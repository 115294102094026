.SectOneDS {
    width: 100%;
    background-image: url('../../assets/dataSheet/DSBGOne.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.titleDS {
    width: 100%;
    padding: 50px 5% 25px 5%;
}
.pTitleDS {
    width: 100%;
    text-align: left;
    margin: 0;
    font-family: SweetSansProHeavy;
    color: #fff;
    font-size: 65px;
    line-height: 60px;
}
.subTitleDS {
    width: 100%;
    padding: 25px 5% 50px 5%;
}
.pSubTitleDS {
    width: 100%;
    text-align: right;
    margin: 0;
    font-family: SweetSansProHeavy;
    color: #fff;
    font-size: 35px;
    line-height: 30px;
}
.pSubTitleDS2 {
    font-size: 55px;
    line-height: 50px;
}
.pSubTitleDSY {
    color: rgb(243, 197, 31);
}


/* Section Two */

.SectTwoDS {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(243, 197, 31);
}
.SectTwoDSL{
    background-color: rgb(243, 197, 31);
    width: 60%;
    padding: 50px 2.5% 50px 5%;
}
.txtSectTwoDSLW {
    color: #fff;
}
.txtSectTwoDSL {
    margin: 0;
    color: #403c3c;
    width: 100%;
    text-align: left;
}
.SectTwoDSR{   
    width: 40%;
    padding: 50px 5% 50px 2.5%;
    background-color: #000;
}
.SectTwoDSR1-2{   
    width: 40%;
    padding: 50px 5% 50px 2.5%;
    background-color: #e0dcdc;
}
.SectTwoDSR2{
    width: 100%;
}
.imgSect2DS {
    width: 100%;
    height: 180px;
    background-image: url('../../assets/dataSheet/DSBGTwo.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

/* Section txtTitle  */

.txtTitleBGG{
    width: 100%;
    padding: 25px 5%;
    background-color: #403c3c;
}
.textTDS {
    width: 100%;
    margin: 0;
    text-align: center;
    color: #fff;
    font-size: 20px;
    font-family: SweetSansProHeavy;
}


/* Section Three */

.SectThreeDS {
    width: 100%;
    padding: 50px 5%;
    background-color: rgb(243, 197, 31);
}

.contIMGSThreeDS {
    width: 100%;
    height: 250px;
    background-image: url('../../assets/dataSheet/imgSectThree.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.txtSectThreeDS{
    margin: 0;
    color: #403c3c;
    width: 100%;
    text-align: left;
}
.txtBold {
    font-family: SweetSansProBold;
}


/* Section Four */

.SectFourDS {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #585454;
}

.contImgSectFourDS {
    width: 40%;
    padding: 50px 2.5% 50px 5%;
    background-color: rgb(243, 197, 31);
}
.contImgSectFourDS2 {
    width: 35%;
    padding: 50px 0% 50px 5%;
    background-color: rgb(243, 197, 31);
}
.imgSEctFourDS{
    height: 200px;
    background-image: url('../../assets/dataSheet/imgSectFourDS.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.contTxtSectFourDS {
    margin: 0;
    width: 60%;
    padding: 50px 5% 50px 2.5%;
}
.contTxtSectFourDStxt{
    margin: 0;
    width: 100%;
    color: #fff;
}

.SectFourDS2 {
    background-color: #e0dcdc;
}
.transparentStyle{
    background-color: transparent;
}
.blackColor {
    color: #000;
}
.bgSectFiveDS {
    background-image: url('../../assets/dataSheet/ImgSectFive.png');
    background-position: right;
}

.SectTwoDSR2{
    height: 300px;
    background-image: url('../../assets/dataSheet/DSImgSeven.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.SectTwoDSR3{
    background-color: #e0dcdc;
    height: 440px;
    background-image: url('../../assets/dataSheet/imgReva.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.SectTwoDSR4{
    background-color: #e0dcdc;
    height: 350px;
    background-image: none;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.SectTwoDSR42{
    background-image: url('../../assets/dataSheet/Doc.png');
}



/* Section Eight */

.SectEightDS{
    width: 100%;
    padding: 50px 5%;
    background-color: #585454;
}
.contStImgSectEDS{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contLSEDS {
    width: 30%;
    max-width: 200px;
    height: 130px;
    background-image: url('../../assets/dataSheet/iconOne.png');
    background-position: right -25% bottom 0%;
    background-repeat: no-repeat;
    background-size: contain;
}
.contRSEDS {
    width: 70%;
    max-width: 470px;
    height: 95px;
    background-color: transparent;
    background-image: url('../../assets/dataSheet/goldBar.png');
    background-position: right 150% bottom -16px;
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.img2SEDS {
    background-image: url('../../assets/dataSheet/iconTwo.png');
    background-position: right -15px bottom -5px;
    height: 145px;
}
.img2SEDS2 {
    background-image: url('../../assets/dataSheet/greyBar.png');
    background-position: right 135% bottom -16px;
    max-width: 470px;
    height: 125px;
}

.img3SEDS {
    background-image: url('../../assets/dataSheet/iconThree.png');
    background-position: right -14px bottom -5px;
    height: 185px;
}
.img3SEDS3 {
    background-image: url('../../assets/dataSheet/goldBar2.png');
    background-position: right 138% bottom 14px;
    max-width: 470px;
    height: 165px;
}
.mTopDesktop{
    margin-top: 25px;
}

.img4SEDS {
    background-image: url('../../assets/dataSheet/iconFour.png');
    background-position: right -14px bottom -5px;
    height: 205px;
}
.img4SEDS4 {
    background-image: url('../../assets/dataSheet/greyBar2.png');
    background-position: right 148% bottom 14px;
    max-width: 470px;
    height: 185px;
}


.txtGoldBar{
    margin: 0;
    width: 70%;
    color: #403c3c;
    font-size: 11px;
    padding-bottom: 7.5px;
}
.txtGoldBar2{
    margin: 0;
    width: 70%;
    color: #fff;
    font-size: 11px;
    padding-bottom: 5px;
}
.txtGoldBar3{
    margin: 0;
    width: 70%;
    color: #fff;
    font-size: 11px;
    padding-bottom: 45px;
}
.txtGoldBar4{
    margin: 0;
    width: 70%;
    color: #fff;
    font-size: 11px;
    padding-bottom: 48px;
}
/* Querys */

/* 1333px */

@media screen and (max-width: 1333px) {
    .SectTwoDSR3 {
        height: 500px ;
    }
}
@media screen and (max-width: 1333px) {
    .SectTwoDSR4 {
        height: 440px ;
    }
}

/* 1036px */

@media screen and (max-width: 1036px) {
    .SectTwoDSR3 {
        height: 550px ;
    }
}
@media screen and (max-width: 1036px) {
    .SectTwoDSR4 {
        height: 525px ;
    }
}


/* 882px */
@media screen and (max-width: 882px) {
    .titleDS {
        padding:25px 5% 12.5px 5%;
    }
}
@media screen and (max-width: 882px) {
    .subTitleDS {
        padding:12.5px 5% 25px 5%;
    }
}
@media screen and (max-width: 882px) {
    .pTitleDS {
        font-size: 45px;
        line-height: 40px;
    }
}
@media screen and (max-width: 882px) {
    .pSubTitleDS {
        font-size: 20px;
        line-height: 20px;
    }
}
@media screen and (max-width: 882px) {
    .pSubTitleDS2 {
        font-size: 35px;
        line-height: 30px;
    }
}



@media screen and (max-width: 882px) {
    .SectTwoDSL {
        width: 62.5%;
        padding: 25px 2.5% 25px 5% ;
    }
}
@media screen and (max-width: 882px) {
    .SectTwoDSR {
        width: 22.5%;
        padding: 25px 5% 25px 2.5% ;
    }
}
@media screen and (max-width: 882px) {
    .SectTwoDSR {
        background-color: transparent;
    }
}
@media screen and (max-width: 882px) {
    .txtSectTwoDSL {
        font-size: 16px;
    }
}
@media screen and (max-width: 882px) {
    .imgSect2DS {
        height: 120px;
    }
}




@media screen and (max-width: 882px) {
    .SectThreeDS {
        padding: 25px 5%;
    }
}
/* @media screen and (max-width: 882px) {
    .txtTitleBGG {
        padding: 25px 5%;
    }
} */
@media screen and (max-width: 882px) {
    .textTDS {
        font-size: 16px;
    }
}
@media screen and (max-width: 882px) {
    .contIMGSThreeDS {
        height: 180px;
    }
}

@media screen and (max-width: 882px) {
    .SectTwoDS2 {
        flex-direction: column;
    }
}

@media screen and (max-width: 882px) {
    .SectTwoDSL2, .SectTwoDSR2 {
        width: 100%;
        padding: 25px 5%;
    }
}
@media screen and (max-width: 882px) {
    .SectTwoDSR2{
        background-size: cover;
    }
}

@media screen and (max-width: 882px) {
    .SectTwoDSR3 {
        height: 130px ;
        width: 100%;
        padding: 0px 5% 25px 5%;
    }
}

@media screen and (max-width: 882px) {
    .SectTwoDSR4 {
        height: 130px ;
        width: 100%;
        padding: 0px 5% 25px 5%;
    }
}
@media screen and (max-width: 882px) {
    .SectTwoDSL3 {
        width: 100%;
        padding: 25px 5% 0px 5%;
    }
}

@media screen and (max-width: 882px) {
    .SectTwoDSL4 {
        width: 100%;
        padding: 25px 5% 25px 5%;
    }
}


@media screen and (max-width: 882px) {
    .SectTwoDSR1-2 {
        width: 100%;
        padding: 25px 5% 0px 5%;
        background-color: transparent;
    }
}
@media screen and (max-width: 882px) {
    .SectTwoDSR42 {
        background-size: contain;
        background-color: transparent;
    }
}




/* 818px */

@media screen and (max-width: 818px) {
    .imgSEctFourDS {
        height: 250px;
    }
}

/* 750px */

@media screen and (max-width: 750px) {
    .contLSEDS {
        height: 95px;
        max-width: 70px;
        background-position: right -8px bottom -5px;
    }
}
@media screen and (max-width: 750px) {
    .contRSEDS {
        max-width: 235px;
        background-position: right 8px bottom -5px;
    }
}
@media screen and (max-width: 750px) {
    .img2SEDS {
        height: 75px ;
        background-position: right -8px bottom 0px;
        /* background-position: right -15px bottom -5px; */
    }
}
@media screen and (max-width: 750px) {
    .img2SEDS2 {
        height: 66px ;
    }
}
@media screen and (max-width: 750px) {
    .mTopDesktop2 {
        margin-top:20px ;
    }
}

@media screen and (max-width: 750px) {
    .img3SEDS {
        height: 100px ;
        background-position: right -8px bottom 0px;
        /* background-position: right -15px bottom -5px; */
    }
}
@media screen and (max-width: 750px) {
    .img3SEDS3 {
        height: 90px ;
        background-position: right 10px bottom 10px;
    }
}

@media screen and (max-width: 750px) {
    .img4SEDS {
        height: 112px ;
        background-position: right -8px bottom 0px;
        /* background-position: right -15px bottom -5px; */
    }
}
@media screen and (max-width: 750px) {
    .img4SEDS4 {
        height: 105px;
        background-position: right 10px bottom 12px;
    }
}

@media screen and (max-width: 750px) {
    .txtGoldBar, .txtGoldBar2, .txtGoldBar3, .txtGoldBar4 {
        font-size: 6px;
    }
}
@media screen and (max-width: 750px) {
    .txtGoldBar {
        padding-bottom: 4px;
    }
}
@media screen and (max-width: 750px) {
    .txtGoldBar2 {
        padding-bottom: 4px;
    }
}
@media screen and (max-width: 750px) {
    .txtGoldBar3 {
        padding-bottom: 18px;
    }
}
@media screen and (max-width: 750px) {
    .txtGoldBar4 {
        padding-bottom: 22px;
    }
}


/* 665px */
@media screen and (max-width: 665px) {
    .imgSEctFourDS {
        height: 300px;
    }
}




/* 550px */

@media screen and (max-width: 550px) {
    .pTitleDS {
        font-size: 30px;
        line-height: 25px;
    }
}
@media screen and (max-width: 550px) {
    .pSubTitleDS {
        font-size: 12px;
        line-height: 12px;
    }
}
@media screen and (max-width: 550px) {
    .pSubTitleDS2 {
        font-size: 22px;
        line-height: 18px;
    }
}

@media screen and (max-width: 550px) {
    .txtSectTwoDSL {
        font-size: 12px;
    }
}

@media screen and (max-width: 550px) {
    .textTDS {
        font-size: 12px;
    }
}

@media screen and (max-width: 550px) {
    .contIMGSThreeDS {
        height: 140px;
    }
}
@media screen and (max-width: 550px) {
    .txtSectThreeDS, .contTxtSectFourDStxt {
        font-size: 14px;
    }
}

/* @media screen and (max-width: 550px) {
    . {
        
    }
} */
@media screen and (max-width: 550px) {
    .SectFourDS {
        flex-direction: column;
    }
}


@media screen and (max-width: 550px) {
    .contImgSectFourDS, .contTxtSectFourDS {
        width: 100%;
        padding: 25px 5%;
    }
}
@media screen and (max-width: 550px) {
    .imgSEctFourDS {
        height: 100px;
    }
}

@media screen and (max-width: 550px) {
    .bgSectFiveDS {
        background-position: center;
    }
}