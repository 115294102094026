@font-face {
  font-family: SweetSansProBold;
  src: url('../src/Fonts/SweetSansProBold.otf') format('opentype');
  font-weight: bold;
}

@font-face {
  font-family: SweetSansProReg;
  src: url('../src/Fonts/SweetSansProRegular.otf') format('opentype');
  font-weight: normal;
}

@font-face {
  font-family: SweetSansProMed;
  src: url('../src/Fonts/SweetSansProMedium.otf') format('opentype');
  font-weight: 600;
}

@font-face {
  font-family: SweetSansProHeavy;
  src: url('../src/Fonts/SweetSansProHeavy.otf') format('opentype');
  font-weight: 900;
}


.amplify-button[data-variation='primary'] {
    width: auto;
    margin: 0px 25% 0px 25%;
    background-color: rgb(243, 197, 31);
    font-weight: bold;
    color: #fff;
    text-align: center;
    padding: 10px 30px;
    border: none;
    border-radius: 8px;
    text-decoration: none;
    font-size: 14px;
}
.amplify-button:hover{
  background-color: rgb(243, 197, 31) !important;
}





body {
  padding: 0;
  margin: 0;
  background-color: black;
  font-family: SweetSansProReg;
}

.btnSignOut{
  font-size: 14px;
  background-color: rgb(243, 197, 31);
  padding: 5px 12px;
  border: 2px solid black;
  border-radius: 10px;
  cursor: pointer;
}
