.contAuth{
    background-color: #000;
    background-image: url('../assets/BGSectNine.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.contWelcomeSI {
    width: 100%;
    padding: 50px 60px 50px 60px;
    background-color: rgba(255,255,255,0.1);
    display: flex;
    align-items: center;
    justify-content:center ;
    flex-direction: column;
}

.txth1title{
    color: #fff;
    width: 100%;
    text-align: left;
    margin: 0;
}
.txth1title1{
    color: #fff;
    width: 100%;
    text-align: center;
    margin: 0;
    font-size: 18px;
    font-family: SweetSansProReg;
}
.amountTKCH1{
    width: 60%;
    border: 1px solid #fff;
    border-radius: 8px;
    padding: 6px 15px 6px 15px;
    color: #fff;
    background-color: rgba(243, 197, 31, 0.2);
    outline: none;
    text-align: center;
}
.amountTKCH1:focus{
    border:2px solid #fff;
}
.txth1subtitle{
    color: #fff;
    width: 100%;
    text-align: left;
    margin: 0;
    font-size: 16px;
}
.txth1subtitle1{
    color: #fff;
    width: 100%;
    text-align: left;
    margin: 0;
    font-size: 16px;
    margin-bottom: 5px;
}

.btnSignOut {
    font-size: 12px;
    border-radius: 25px;
    font-weight: bold;
    width: 200px;
    border: 3px solid white;
    background-color:rgb(243, 197, 31);
    cursor: pointer;
    padding: 5PX 0PX;
    margin-bottom: 6px;
}
.contBtnsSignIn{
    width: 100%;
    display: flex;
    align-items: left;
    justify-content: center;
    flex-direction: column;
}

.contInputMailLogin{
    width: 80%;
}

.dflexx{
    display: flex;
}
.dnonee{
    display: none !important;
}
.dblockk{
    display: block !important;
}
.btnSendFormLoginGoogle{
    width: 80%;
    background-color: #dc4854;
    font-weight: bold;
    color: #fff;
    text-align: center;
    padding: 10px 30px;
    border: none;
    border-radius: 4px;
    text-decoration: none;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.btnSendFormLoginFB{
    width: 80%;
    background-color: #1884fc;
    font-weight: bold;
    color: #fff;
    text-align: center;
    padding: 10px 30px;
    border: none;
    border-radius: 4px;
    text-decoration: none;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.btnSendFormLogin1{
    width: 80%;
    background-color: #f0ac0c;
    font-weight: bold;
    color: #fff;
    text-align: center;
    padding: 10px 30px;
    border: none;
    border-radius: 4px;
    text-decoration: none;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.btnSendFormLogin2{
    width: 100%;
    background-color: #f0ac0c;
    font-weight: bold;
    color: #fff;
    text-align: center;
    padding: 10px 30px;
    border: none;
    border-radius: 4px;
    text-decoration: none;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.btnSendFormLogin3{
    width: 40%;
    background-color: #f0ac0c;
    font-weight: bold;
    color: #fff;
    text-align: center;
    padding: 10px 30px;
    border: none;
    border-radius: 4px;
    text-decoration: none;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.mtop {
    margin-top: .5em;
}

.btnCreateAccountLink1 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

.mbtm{
    margin-bottom: 5px;
}
.contBtnSellQuality{
    width: 100%;
    display: flex;
    align-items: left;
    justify-content: center;
    flex-direction: column;
}
.contName{
    color: #f0ac0c;
    margin: 0;
}
.homeSectNine1{
    width: 100%;
    padding: 50px 5%;
    background-color: #000;
    background-image: url('../assets/BGSectNine.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
}
.mleft{
    margin-left: 5px;
}
#myButtonAction{
    opacity: 0.6;
}
.txterrorMail{
    font-size: 12px;
}

.errorMessageTxt{
    margin: 0 0 1em 0;
    color: #fff;
}
.txtErrorSI{
    margin: 0;
    color: #f0ac0c;
    font-family: SweetSansProMed;
    font-size: 14px;
    text-align: center;
}


@media screen and (max-width: 600px) {
    .txth1title {
        font-size: 18px;
    }
}
@media screen and (max-width: 600px) {
    .txth1subtitle {
        font-size: 14px;
    }
}
@media screen and (max-width: 600px) {
    .btnSendFormLogin2, .btnSendFormLogin1, .btnSendFormLoginGoogle, .btnSendFormLoginFB {
        font-size: 10px;
    }
}
@media screen and (max-width: 600px) {
    .txth1title1 {
        font-size: 14px;
    }
}
@media screen and (max-width: 650px) {
    .txtErrorSI {
        font-size: 12px;
    }
}
.ndfibsfbjerbgjk{
    color: #fff;
}