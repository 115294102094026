.txtTitleContactsSimple{
    margin: 0px;
    width: 100%;
    text-align: center;
    font-size: 21px;
    color: #fff;
    padding-bottom: 2%;
}
.contMailsContact{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.contMailsContact2{
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.contMailContactL{
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.contMailContactR{
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.txtMailContact{
    text-decoration: none;
    margin: 0;
    color: #fff;
}

/* Querys */

@media screen and (max-width: 882px) {
    .contMailsContact2 {
        flex-direction: column;
    }
}