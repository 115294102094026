.headerCont{
    max-width: 100%;
    width: 100%;
    height: 130px;
    background-color: black;
    background-image: url("../../assets/NavBar.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 1% 1.5%;
}
.navCont{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.contLogo{
    height: 100%;
    width: 10%;
    background-image: url("../../assets/logoToken.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.underlineLogIn{
    text-decoration: underline;
}
.spaceMenu {
    width: 2%;
}
.contMenu{
    height: 100%;
    width: 88%;
}
.menuTop{
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.menuBoot{
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.setlanguageCont{
    width: 15%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center
}

.linksNavBar{
    width: 15%;
}


/* Header responsive */

.panel{
    position: fixed;
    padding: 1.5% 2%;
    z-index: 998;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color:rgba(243, 197, 31, .95);
    transition: transform 0s ease;
    transform: translate(0, -100%);
    display: flex;
    align-items: center;
    justify-content: center;
}
.panel.is-active{
    transform: translate(0, 0);
}

.headerContMobile{
    position: fixed;
    width: 100%;
    z-index: 999;
    height: 75px;
    padding: 1.5% 2%;
    background-color: black;
    background-image: url("../../assets/NavBar.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: space-between;
    align-items: center;
    display: none;
}
.panel-btn{
    /* z-index: 999; */
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    border: 2px solid white;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(243, 197, 31);
}
.hamburger{
   height: 24px;
   width: 30px;
   padding: 0px; 
}
.hamburger-box{
    height: 24px;
    width: 30px; 
}
.hamburger-inner{
    height: 4px;
    width: 30px;
}
.hamburger-inner::before{
    height: 4px;
    width: 30px;
}
.hamburger-inner::after{
    height: 4px;
    width: 30px;
}
.spaceMob{
    width: 30%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center
}
.contLogoMob{
    height: 50px;
    width: 32%;
    background-image: url("../../assets/logoToken.png");
    background-position: left;
    background-repeat: no-repeat;
    background-size: contain;
}

.menuMobileCont{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menuMobile{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
}
.menuMobileL{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
}
.linksNavBarMob{
    padding: 5px;
    width: 100%;
    display: block;
    transition: all 0.3 ease;
}


/* Querys */

@media screen and (max-width: 882px) {
    .headerCont {
        display: none;
    }
}

@media screen and (max-width: 882px) {
    .headerContMobile {
        display: flex;
    }
}
@media screen and (min-width: 882px) {
    .panel {
        display: none;
    }
}