input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number] { -moz-appearance:textfield; }

.contTokenSell{
    background-color: #fff;
    background-image: url('../assets/BGSectNine.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.sectOneTokenSell{
    width: 100%;
    padding: 25px 60px 25px 60px;
    background-color: rgba(255,255,255,0.05);
    display: flex;
    align-items: center;
    justify-content:center ;
}
.contLogoTKS{
    height: 75px;
    width: 75px;
    background-image: url("../assets/logoToken.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.sectTwoTokenSell{
    width: 100%;
    padding: 25px 60px 50px 60px;
    background-color: rgba(255,255,255,0.15);
    display: flex;
    align-items: center;
    justify-content:center ;
    flex-direction: column;
}
.txtHowManyTkch{
    color: #fff;
    margin: 0;
    width: 100%;
    text-align: center;
    font-family: SweetSansProBold;
}
.contCalc{
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid #fff;
    border-radius: 10px;
    padding: 20px 15px 20px 15px;
    background-color: rgba(255,255,255,0.05);
}
.txtCalc{
    margin: 0;
    color: #fff;
    font-size: 16px;
    font-family: SweetSansProBold;
}
.txtCalc2{
    padding: 6px 15px 6px 15px;
}
.contTwoParts{
    width: 100%;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
}
.contLRTS{
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.txtHowManyTkch2{
    color: #fff;
    margin: 0;
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-family: SweetSansProMed;
}
.contThreePaymentMet{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: 1px solid #fff;
    border-radius: 10px;
    background-color: rgba(243, 197, 31,0.1);
}
.contThreePaymentMet2{
    padding: 1%;
    width: 42%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: 1px solid #fff;
    border-radius: 10px;
    background-color: rgba(255,255,255,0.2);
}
.widthCont{
    width: 28px;
    height: 28px;
    background-color: transparent;
    border: none;
}
.contOneMetP{
    width: 45%;
    background-image: url('../assets/logo-VISA.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    align-items: center;
    justify-content: center;
}
.conTwoMetP{
    width: 45%;
    background-image: url('../assets/logo-Mastercard.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    align-items: center;
    justify-content: center;
}
.contThreeMetP{
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: left;
}
.linktoDB{
    color: #fff;
    font-size: 14px;
    font-family: SweetSansProMed;
    text-decoration: underline;
}






























.amountTKCH{
    width: 100%;
    border: 1px solid #fff;
    border-radius: 8px;
    padding: 6px 15px 6px 15px;
    color: #fff;
    background-color: rgba(255,255,255,0.2);
    outline: none;
    text-align: center;
}
.amountTKCH:focus{
    border:2px solid #fff;
}
.txtError{
    padding-top: .5em;
    margin: 0;
    color: #f0ac0c;
    font-family: SweetSansProMed;
    font-size: 14px;
    text-align: center;
}
.continputPI{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.inputPayIntention{
    width: 10%;
}

@media screen and (max-width: 916px) {
    .contCalc {
        width: 80%;
    }
}
@media screen and (max-width: 916px) {
    .contThreePaymentMet2 {
        width: 70%;
    }
}
@media screen and (max-width: 650px) {
    .contCalc {
        width: 90%;
    }
}
@media screen and (max-width: 650px) {
    .contThreePaymentMet2 {
        width: 80%;
    }
}
@media screen and (max-width: 650px) {
    .sectTwoTokenSell {
        padding: 20px 20px 20px 20px;
    }
}
@media screen and (max-width: 650px) {
    .txtError {
        font-size: 12px;
    }
}

@media screen and (max-width: 500px) {
    .linktoDB {
        font-size: 12px;
    }
}
@media screen and (max-width: 400px) {
    .linktoDB {
        font-size: 10px;
    }
}
