.AUSectOne{
    width: 100%;
    padding: 50px 5%;
    height: 550px;
    background-image: url('../../assets/aboutUs/Sect1AboutUs.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}
.leftAUSecOne{
    height: 100%;
    width: 50%;
    display: flex;
    align-items: flex-end;
    justify-content: left;
}
.txtTitileSectOneAU{
    margin: 0;
    font-size: 56px;
    width: 100%;
    text-align: left;
    color: #fff;
    font-family: SweetSansProHeavy;
}
.rightAUSecOne{
    height: 100%;
    width: 50%;
    display: flex;
    align-items: flex-end;
    justify-content: right;
}
.txtTitileSectOneAU2{
    margin: 0;
    font-size: 28px;
    width: 100%;
    text-align: right;
    color: #fff;
    font-family: SweetSansProHeavy;
}
.yellowText {
    color: #f0ac0c;
}



.AUSectTwo{
    width: 100%;
    background: linear-gradient(#f0ac0c, #fff);
    display: flex;
    align-items: flex-start;
    justify-content: center;
}
.AUSectTwo2{
    width: 100%;
    background: linear-gradient(#f0ac0c, #fff);
    display: flex;
    align-items: flex-start;
    justify-content: center;
}
.twoProfilesRowMob {
    display: none;
}
.contExpert{
    width: 33.33%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.contExpert2{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.contImgAU{
    width: 100%;
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.imgAU1{
    background-image: url('../../assets/aboutUs/CarlosSalas.png');
    margin: 50px 0% 25px 0%;
}
.imgAU2{
    background-image: url('../../assets/aboutUs/Junior.png');
    margin: 50px 0% 25px 0%;
}
.imgAU3{
    background-image: url('../../assets/aboutUs/JosepMaria.png');
    margin: 50px 0% 25px 0%;
}
.imgAU4{
    background-image: url('../../assets/aboutUs/JoseAntonio.png');
    margin: 50px 0% 25px 0%;
}
.imgAU5{
    background-image: url('../../assets/aboutUs/JoaquinNunez.png');
    margin: 50px 0% 25px 0%;
}
.imgAU6{
    width: 200px;
    background-image: url('../../assets/aboutUs/pueyo.jpg');
    margin: 50px 0% 25px 0%;
    border-radius: 50%;
    background-size: cover;
    background-position: top right;
}
.imgAU7{
    width: 200px;
    background-image: url('../../assets/aboutUs/morato.jpg');
    margin: 50px 0% 25px 0%;
    border-radius: 50%;
    background-size: cover;
    background-position: top center;
}

.contTxtDescriptionExpert{
    width: 100%;
    padding: 25px 0% 50px 0%; 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
}
.contTxtDescriptionExpert2{
    width: 100%;
    padding: 25px 0% 50px 0%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
}
.txtDescriptionExpert{
    margin: 0;
    width: 89%;
    padding: 0px 5% 0px 5%;
    text-align: left;
}

.borderBlack{
    border-right: 2px solid black;
    border-left: 2px solid black;
}
.borderBlack2{
    border-left: 2px solid black;
}
.borderBlack22{
    border-left: 2px solid black;
}

/* Media Querys */

/* 1000px */

@media screen and (max-width: 1000px) {
    .AUSectOne {
        height: 450px;
    }
}
@media screen and (max-width: 1000px) {
    .txtTitileSectOneAU {
        font-size: 46px;
    }
}
@media screen and (max-width: 1000px) {
    .txtTitileSectOneAU2 {
        font-size: 22px;
    }
}


@media screen and (max-width: 882px) {
    .AUSectTwo {
        display: none;
    }
}
@media screen and (max-width: 882px) {
    .twoProfilesRowMob {
        display: flex;
    }
}




/* 882px */

@media screen and (max-width: 882px) {
    .AUSectOne {
        height: 350px;
        padding: 25px 5%;
    }
}
@media screen and (max-width: 882px) {
    .txtTitileSectOneAU {
        font-size: 40px;
    }
}
@media screen and (max-width: 882px) {
    .txtTitileSectOneAU2 {
        font-size: 19px;
    }
}
@media screen and (max-width: 882px) {
    .AUSectTwo {
        flex-direction: column;
        background: linear-gradient(#f0ac0c, #f0ac0c);
    }
}
@media screen and (max-width: 882px) {
    .contExpert {
        width: 100%;
    }
}
@media screen and (max-width: 882px) {
    .borderBlack, .borderBlack2 {
        border: 0;
    }
}
@media screen and (max-width: 882px) {
    .imgAU1, .imgAU2, .imgAU3, .imgAU4, .imgAU5, .imgAU6, .imgAU7 {
        margin: 25px 0% 25px 0%;
    }
}
@media screen and (max-width: 882px) {
    .contTxtDescriptionExpert, .contTxtDescriptionExpert2 {
        padding: 25px 0%;
    }
}

/* 700px */
@media screen and (max-width: 700px) {
    .AUSectOne {
        height: 300px;
    }
}
@media screen and (max-width: 700px) {
    .txtTitileSectOneAU {
        font-size: 30px;
    }
}
@media screen and (max-width: 700px) {
    .txtTitileSectOneAU2 {
        font-size: 14px;
    }
}

/* 500px */
@media screen and (max-width: 550px) {
    .AUSectTwo2 {
        flex-direction: column;
        background: linear-gradient(#f0ac0c, #f0ac0c);
    }
}
@media screen and (max-width: 550px) {
    .contExpert2 {
        width: 100%;
    }
}
@media screen and (max-width: 550px) {
    .borderBlack22 {
        border: 0;
    }
}

@media screen and (max-width: 500px) {
    .AUSectOne {
        height: 250px;
    }
}
@media screen and (max-width: 500px) {
    .txtTitileSectOneAU {
        font-size: 25px;
    }
}
@media screen and (max-width: 500px) {
    .txtTitileSectOneAU2 {
        font-size: 12px;
    }
}

/* 424px */

@media screen and (max-width: 424px) {
    .AUSectOne {
        height: 200px;
    }
}
@media screen and (max-width: 424px) {
    .txtTitileSectOneAU {
        font-size: 20px;
    }
}
@media screen and (max-width: 424px) {
    .txtTitileSectOneAU2 {
        font-size: 9.5px;
    }
}
