.historySectOne{
    width: 100%;
    background-image: url('../../assets/history/bgShoes.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: top;
    justify-content: center;
}
.contleftSectOneHist{
    padding: 50px 2.5% 50px 5%;
    width: 70%;
}
.txtTitleHist{
    width: 100%;
    text-align: left;
    margin: 0;
    font-size: 37px;
    color: white;
}
.txtfontSHist{
    font-size: 32px;
    color: #000;
}
.contTxtSectOneHist{
    padding: 50px 5% 50px 5%;
    width: 30%;
    background-color:rgb(243, 197, 31) ;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.contComImg{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
}
.comImg{
    width: 50px;
    height: 50px;
    background-image: url('../../assets/history/comOne.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.contComImg2{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
}
.comImg2{
    width: 50px;
    height: 50px;
    background-image: url('../../assets/history/comTwo.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.txtSectOneHist {
    margin: 0;
    width: 100%;
    text-align: justify;
    color: #403c3c;
}
.txtNameJosep{
    margin: 0;
    width: 100%;
    text-align: center;
    font-size: 18px;
    color: #403c3c;
}

/* Second Section */


.historySectTwo {
    width: 100%;
    background-color: #403c3c;
    display: flex;
    align-items: center;
    justify-content: center;
}
.contLSect2H {
    background-color: #403c3c;
    width: 40%;
    height: 350px;
    background-image: url('../../assets/history/JMM.png');
    background-size:800px;
    background-position: top center;
    background-repeat: no-repeat;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.contLSect2H2 {
    background-color: #403c3c;
    width: 40%;
    height: 500px;
    background-image: url('../../assets/history/DAMarad.png');
    background-size:600px;
    background-position: top right;
    background-repeat: no-repeat;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.contLSect2H3 {
    width: 50%;
    height: 500px;
    background-image: url('../../assets/history/img2.png');
    background-size: 850px;
    background-position: top right;
    background-repeat: no-repeat;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.contRSect2H {
    width: 60%;
    padding: 0px 5%;
    background-color: #403c3c;
}

.txtJMM{
    width: 100%;
    margin: 0;
    color: rgb(243, 197, 31);
    text-align: left;
}

/* Section Three */

.contDescrptionPhoto {
    width: 100%;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
}
.colDescPhotoBG {
    background-color: #403c3c;
}
.bgColor_353534{
    background-color: #353534;
}
.contBordersImgDesc{
    margin: 20px 0px;
    width: 300px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('../../assets/history/borderTxtDesc.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.txtDescriptionPhotos{
    padding: 20px 40px;
    margin: 0;
    color: #fff;
    font-size: 9px;
    text-align: center;
}
.bgIMGSect4{
    background-image: url('../../assets/history/bgIMGSect3.png');
    background-position: top center;
}
.bgColor_rgaYellow{
    background-color: rgb(243, 197, 31);
}
.colorTxtGrey{
    color: #403c3c;
}

/* Section Five */

.historySectFive {
    width: 100%;
    padding: 50px 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #403c3c;
}
.txtSectFiveHisto{
    margin: 0;
    width: 75%;
    font-size:16px;
    text-align: left;
    color: #fff;
}

/* Section Six */

.historySectSix{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Section Seven */

.bgSectSev1{
    background-image: url('../../assets/history/img3.png');
}
.bgSectSev2{
    background-image: url('../../assets/history/img4.png');
}





.historySectEight{
    width: 100%;
    height: 600px;
    background-color: #000;
    background-image: url('../../assets/history/bgSectEight.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

/* Media Querys */

/* 1200px */
@media screen and (max-width: 1200px) {
    .contLSect2H2 {
        height: 450px;
        background-size:450px;
    }
}
@media screen and (max-width: 1200px) {
    .contLSect2H3 {
        height: 400px;
        background-size:600px;
    }
}
@media screen and (max-width: 1200px) {
    .historySectEight {
        height: 500px;
    }
}
@media screen and (max-width: 1000px) {
    .contLSect2H2 {
        height: 400px;
        background-size:400px;
    }
}
@media screen and (max-width: 1000px) {
    .historySectEight {
        height: 400px;
       
    }
}


/* 882px */
@media screen and (max-width: 882px) {
    .historySectOne {
       flex-direction: column;
    }
}
@media screen and (max-width: 882px) {
    .contleftSectOneHist, .contTxtSectOneHist {
       width: 100%;
       padding: 25px 5%;
    }
}

@media screen and (max-width: 882px) {
    .txtTitleHist {
       text-align: right;
       padding-bottom: 50px;
    }
}
@media screen and (max-width: 882px) {
    .contleftSectOneHist {
        background-image: url('../../assets/history/bgShoes.png');
        background-position: left center;
        background-repeat: no-repeat;
        background-size: contain;
    }
}

@media screen and (max-width: 882px) {
    .historySectTwo {
       flex-direction: column;
    }
}
@media screen and (max-width: 882px) {
    .contLSect2H {
       width: 100%;
       height: 550px;
       background-size:1200px;
    }
}
@media screen and (max-width: 882px) {
    .contLSect2H2 {
       width: 100%;
       height: 750px;
       background-size:880px;
    }
}
@media screen and (max-width: 882px) {
    .contRSect2H {
       width: 100%;
       padding: 25px 5%;
    }
}
@media screen and (max-width: 882px) {
    .txtJMM {
       font-size: 12px;
    }
}
@media screen and (max-width: 882px) {
    .historySectFive {
       padding: 25px 5%;
    }
}
@media screen and (max-width: 882px) {
    .txtSectFiveHisto {
       font-size: 14px;
    }
}
@media screen and (max-width: 882px) {
    .historySectSix {
        flex-direction: column;
    }
} 

@media screen and (max-width: 882px) {
    .contLSect2H3 {
        width:100%;
        height: 500px;
        background-size:950px;
    }
}
@media screen and (max-width: 882px) {
    .historySectEight {
        height: 350px;
       
    }
}

/* 794 */
@media screen and (max-width: 794px) {
    .contLSect2H3 {
        width:100%;
        height: 450px;
        background-size:850px;
    }
}
@media screen and (max-width: 794px) {
    .historySectEight {
        height: 320px;
       
    }
}


/* 600 */

@media screen and (max-width: 600px) {
    .txtfontSHist {
       font-size: 25px;
    }
}
@media screen and (max-width: 600px) {
    .txtTitleHist {
       font-size: 20px;
    }
}
@media screen and (max-width: 600px) {
    .comImg, .comImg2 {
       width: 35px;
       height: 35px;
    }
}
@media screen and (max-width: 600px) {
    .contLSect2H {
       height: 350px;
       background-size:900px;
    }
}
@media screen and (max-width: 600px) {
    .contLSect2H2 {
       height: 530px;
       background-size:585px;
    }
}
@media screen and (max-width: 600px) {
    .txtSectFiveHisto {
       width: 100%;
    }
}

@media screen and (max-width: 600px) {
    .contLSect2H3 {
        width:100%;
        height: 400px;
        background-size:600px;
    }
}

@media screen and (max-width: 600px) {
    .historySectEight {
        height: 280px;
       
    }
}

/* 470 */

@media screen and (max-width: 470px) {
    .contLSect2H {
       height: 280px;
       background-size:700px;
    }
}
@media screen and (max-width: 470px) {
    .contLSect2H2 {
       height: 450px;
       background-size:485px;
    }
}

@media screen and (max-width: 470px) {
    .contLSect2H3 {
        width:100%;
        height: 350px;
        background-size:470px;
    }
}

@media screen and (max-width: 400px) {
    .contLSect2H3 {
        width:100%;
        height: 320px;
        background-size:420px;
    }
}

@media screen and (max-width: 470px) {
    .historySectEight {
        height: 220px;
    }
}