.compHeadM{
    width: 100%;
    height: 75px;
    padding: 1.5% 2%;
    display: none;
}
.homeSectOne{
    width: 100%;
    height: 550px;
    padding: 50px 5%;
    background-color: #000;
    background-image: url('../../assets/homeSect1.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: flex-end;
    justify-content: right;
}
.contTitleHomeSect1{
    width: 100%;
    overflow: hidden;
}
.titleHomeSect1{
    width: 100%;
}
.textTitleHomeSect1Min{
    font-family: SweetSansProHeavy;
    font-size: 50px;
    line-height: 45px;
}
.textTitleHomeSect1{
    color: #fff;
    margin: 0px;
    text-align: right;
    font-size: 72px;
    font-weight: bold;
    line-height: 60px;
}
.textTitleHomeSect1Yellow{
    font-family: SweetSansProHeavy;
    color: rgb(243, 197, 31);
}
.transition1{
    font-family: SweetSansProHeavy;
    line-height: 0% !important;
    animation-duration: 2s;
    animation-name: slidetitle;
}
.transition2{
    animation-duration: 3s;
    animation-name: slidetitle2;
}

/* Section two */

.homeSectTwo{
    background-color: #f0ac0c;
    width: 100%;
    padding: 50px 5%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.stl{
    width: 40%;
    text-align: left;
}
.txtST{
    font-family: SweetSansProHeavy;
    margin: 0;
    font-size: 24px;
    font-weight: bold;
}
.txtSTW{
    color: #fff;
}
.str{
    width: 50%;
}
.txtSTDesct{
    margin: 0;
    font-size: 18px;
    color: #403c3c;
}
.contBtnST{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.contBtnWVideo{
    text-decoration: none;
    color: #f0ac0c;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #000;
    border-radius: 50px;
    padding: 1% 1% 1% 3%;
}
.btnWVideo{
    margin: 0;
    color: rgb(243, 197, 31);
    font-size: 11px;
    font-weight: bold;
    background-color: transparent;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.btnWVideo222{
    margin: 0;
    color: rgb(243, 197, 31);
    font-size: 11px;
    font-weight: bold;
    background-color: transparent;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Section Three */

.homeSectThree{
    width: 100%;
    height: 700px;
    padding: 50px 5%;
    background-color: #000;
    background-image: url('../../assets/homeSect3.png');
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.SThrL{
    width: 40%;
}
.SThrR{
    width: 50%;
}
.txtSThree{
    font-family: SweetSansProHeavy;
    margin: 0;
    font-size: 70px;
    font-weight: bold;
    line-height: 65px;
}
.txtSThreeSub{
    margin: 0;
    font-size: 18px;
    color: #fff;
}

.sectionTextMobile{
    width: 100%;
    padding: 25px 5%;
    background-color: #f0ac0c;
    display: none;
}
.txtSThreeSubM{
    margin: 0;
    font-size: 18px;
    color: #403c3c;
}

/* Section Four */

.homeSectFour{
    width: 100%;
    height: 350px;
    padding: 50px 5%;
    background-color: #000;
    background-image: url('../../assets/homeSect4.png');
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.txtFourSectTitle{
    font-family: SweetSansProHeavy;
    margin: 0;
    width: 100%;
    text-align: center;
    font-size: 50px;
    font-weight: bold;
}
.contSubtitleSectFour{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.SectFL{
    width: 50%;
}
.SectFR{
    width: 50%;
    align-items: center;
    justify-content: center;
}
.txtFourSectSubTitle{
    font-family: SweetSansProBold;
    margin: 0;
    width: 100%;
    text-align: center;
    font-size: 30px;
    font-weight: bold;
}

/* Section Five */

.homeSectFive{
    width: 100%;
    padding: 50px 5%;
    background-color: #f0ac0c;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.contInconsSectFive{
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.icon1SectFive{
    width: 75px;
    height: 75px;
    background-image: url('../../assets/iconSectFive1.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.icon2SectFive{
    width: 90px;
    height: 90px;
    background-image: url('../../assets/iconSectFive2.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.icon2-1SectFive{
    width: 75px;
    height: 75px;
    background-image: url('../../assets/iconSectFive2.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.icon3SectFive{
    width: 75px;
    height: 75px;
    background-image: url('../../assets/iconSectFive3.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.lineSectFive{
    width: 25%;
    height: 5px;
    background-image: url('../../assets/lineSectFive.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.contTxtSectFive{
    margin-top: 10px;
    width: 100%;
    display: flex;
    align-items:flex-start;
    justify-content: space-between;
}
.txtContSFive{
    width: 30%;
}
.txtTitleSectFive{
    width: 100%;
    text-align: center;
    margin: 0;
    color: #fff;
    font-weight: bold;
}
.txtSectFive{
    width: 100%;
    text-align: left;
    padding-top: 10px;
    margin: 0;
    color: #403c3c;
}
.contBtnSFive{
    display: flex;
    align-items: center;
    justify-content: center;
}
.contBtnStartSFive{
    color: #f0ac0c;
    width: 140px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #000;
    border-radius: 50px;
    padding: 1% 1% 1% 3%;
}
.contIconMob{
    width: 100%;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
}

/* Section Six */

.homeSectSix1{
    width: 100%;
    padding: 25px 5%;
    background-color: #403c3c;
    display: flex;
    align-items: center;
    justify-content: center;
}
.txtTitleSectSix{
    width: 100%;
    text-align: center;
    margin: 0;
    color: #f0ac0c;
    font-size: 30px;
    font-weight: bold;
}

.homeSectSix2{
    width: 100%;
    padding: 50px 5%;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content:center;
}
.contSectSixL{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.contSectSixR{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.contlogo1SectSix{
    width: 32%;
    height: 80px;
    background-image: url('../../assets/logo1SectSix.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.contlogo2-2SectSix{
    width: 25%;
    height: 80px;
    background-image: url('../../assets/logo2-2SectSix.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.contlogo2SectSix{
    width: 32%;
    height: 80px;
    background-image: url('../../assets/logo2SectSix.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.contlogo3SectSix{
    width: 45%;
    height: 80px;
    background-image: url('../../assets/logo3SectSix.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.contlogo4SectSix{
    width: 45%;
    height: 80px;
    background-image: url('../../assets/logo4SectSix.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

/* Section Seven */

.homeSectSeven{
    width: 100%;
    padding: 50px 5%;
    background-color: #f0ac0c;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
}
.txtTitleSectSeven{
    font-family: SweetSansProHeavy;
    width: 100%;
    text-align: center;
    margin: 0;
    font-size: 50px;
    color: #403c3c;
    font-weight: bold;
}
.contImgNews{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.imgNews1{
    width: 32%;
    height: 220px;
    background-image: url('../../assets/imgNews1.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.imgNews2{
    width: 32%;
    height: 220px;
    background-image: url('../../assets/imgNews2.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.imgNews3{
    width: 32%;
    height: 220px;
    background-image: url('../../assets/imgNews3.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

/* Section Eight */

.homeSectEight{
    width: 100%;
    padding: 50px 5%;
    background-color: #000;
    background-image: url('../../assets/BGSectEight.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
}
.txtTitleSectEight{
    margin: 0;
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 25px;
}
.conticonsReviews{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.SectEightL{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.SectEightR{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.iconReviews1{
    width: 30%;
    height: 60px;
    background-image: url('../../assets/ReviewsIcon1.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.iconReviews2{
    width: 30%;
    height: 60px;
    background-image: url('../../assets/ReviewsIcon2.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.iconReviews3{
    width: 30%;
    height: 60px;
    background-image: url('../../assets/ReviewsIcon3.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.iconReviews4{
    width: 30%;
    height: 60px;
    background-image: url('../../assets/ReviewsIcon4.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.iconReviews5{
    width: 30%;
    height: 60px;
    background-image: url('../../assets/ReviewsIcon5.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.iconReviews6{
    width: 30%;
    height: 60px;
    background-image: url('../../assets/ReviewsIcon6.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

/* Section Nine */

.homeSectNine{
    width: 100%;
    padding: 50px 5%;
    background-color: #000;
    background-image: url('../../assets/BGSectNine.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
}
.txtTitleSectNine{
    margin: 0;
    margin-bottom: 3%;
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 30px;
}
.contForm{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.form{
    width: 100%;
    padding: 5%;
    border-radius: 10px;
    background-color: rgba(255,255,255,0.15);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.contInputs{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.contInputMail{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
}
.inputfstSectForm{
    padding: 2px 10px;
    outline: none;
    color: #fff;
    width: 48%;
    background-color: transparent;
    border: none;
    border-bottom:2px solid #f0ac0c;
    border-radius: 6px;
}
.inputfstSectForm2{
    padding: 2px 10px 20px 10px;
    outline: none;
    color: #fff;
    width: 100%;
    background-color: transparent;
    border: none;
    border-bottom:2px solid #f0ac0c;
}
.contButtonSendForm{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.btnSendForm{
    width: 160px;
    background-color: #000;
    font-weight: bold;
    color: rgb(243, 197, 31);
    text-align: center;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    text-decoration: none;
}
.inputfstSectForm::placeholder { color: #fff; }
.inputfstSectForm2::placeholder { color: #fff; }

/* Querys */
/* 1200px */
@media screen and (max-width: 882px) {
    .contBtnWVideo {
        width: 135px;
    }
}
@media screen and (max-width: 882px) {
    .contBtnStartSFive {
        width: 160px;
    }
}

@media screen and (max-width: 1200px) {
    .homeSectOne {
        height: 500px;
    }
}
/* 1000px */
@media screen and (max-width: 1000px) {
    .homeSectOne {
        height: 400px;
    }
}
@media screen and (max-width: 1000px) {
    .txtFourSectTitle {
        font-size: 40px;
    }
}
@media screen and (max-width: 1000px) {
    .txtFourSectSubTitle {
        font-size: 25px;
    }
}
/* 882px */
@media screen and (max-width: 882px) {
    .compHeadM {
        display: block;
    }
}
@media screen and (max-width: 882px) {
    .homeSectOne {
        height: 300px;
        padding: 25px 5%;
    }
}
@media screen and (max-width: 882px) {
    .textTitleHomeSect1 {
        font-size: 52px;
        line-height: 45px;
    }
}
@media screen and (max-width: 882px) {
    .textTitleHomeSect1Min {
        font-size: 30px;
        line-height: 25px;
    }
}
@media screen and (max-width: 882px) {
    .homeSectTwo {
        flex-direction: column;
        padding: 25px 5%;
    }
}
@media screen and (max-width: 882px) {
    .stl, .str {
        width: 100%;
    }
}
@media screen and (max-width: 882px) {
    .txtST {
        padding-bottom: 20px;
    }
}
@media screen and (max-width: 882px) {
    .txtSThreeSub {
        display: none;
    }
}
@media screen and (max-width: 882px) {
    .SThrL {
        width: 0%;
    }
}
@media screen and (max-width: 882px) {
    .homeSectThree {
        height: 250px;
        justify-content: center;
        padding: 25px 5%;
    }
}
@media screen and (max-width: 882px) {
    .SThrR {
        width: 100%;
        /* display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: right; */
    }
}
@media screen and (max-width: 882px) {
    .txtSThree {
        width: 100%;
        text-align: right;
        font-size: 50px;
        font-weight: bold;
        line-height: 45px;
    }
}
@media screen and (max-width: 882px) {
    .sectionTextMobile {
        display: flex;
    }
}
@media screen and (max-width: 882px) {
    .homeSectFour {
        height: auto;
        padding: 25px 5%;
    }
}
@media screen and (max-width: 882px) {
    .txtFourSectTitle {
        font-size: 34px;
    }
}

@media screen and (max-width: 882px) {
    .homeSectFive {
        padding:25px 5%;
    }
}
@media screen and (max-width: 882px) {
    .contInconsSectFive {
        display: none;
    }
}
@media screen and (max-width: 882px) {
    .contIconMob {
        display: flex;
    }
}
@media screen and (max-width: 882px) {
    .contTxtSectFive {
        flex-direction: column;
    }
}
@media screen and (max-width: 882px) {
    .txtContSFive {
        width: 100%;
    }
}
@media screen and (max-width: 882px) {
    .paddMob {
        padding-top: 20px;
    }
}
@media screen and (max-width: 882px) {
    .marginMob {
        margin-top: 20px;
    }
}
@media screen and (max-width: 882px) {
    .marginMob2 {
        margin-top: 50px;
    }
}

@media screen and (max-width: 882px) {
    .homeSectSix1 {
        padding: 15px 5%;
    }
}
@media screen and (max-width: 882px) {
    .txtTitleSectSix {
        font-size: 22px;
    }
}

@media screen and (max-width: 882px) {
    .homeSectSix2 {
        flex-direction: column;
        padding: 25px 5%;
    }
}
@media screen and (max-width: 882px) {
    .contSectSixL, .contSectSixR {
        width: 100%;
    }
}
@media screen and (max-width: 882px) {
    .contlogo1SectSix, .contlogo2SectSix, .contlogo3SectSix, .contlogo4SectSix, .contlogo2-2SectSix {
        height: 60px;
    }
}
@media screen and (max-width: 882px) {
    .contSectSixR {
        margin-top: 15px;
    }
}

@media screen and (max-width: 882px) {
    .homeSectSeven {
        padding: 25px 5%;
    }
}
@media screen and (max-width: 882px) {
    .contImgNews {
        flex-direction: column;
    }
}
@media screen and (max-width: 882px) {
    .imgNews1, .imgNews2, .imgNews3 {
        width: 100%;
    }
}


@media screen and (max-width: 882px) {
    .homeSectEight {
        padding: 25px 5%;
    }
}
@media screen and (max-width: 882px) {
    .conticonsReviews {
        flex-direction: column;
    }
}
@media screen and (max-width: 882px) {
    .SectEightL, .SectEightR {
        width: 100%;
    }
}
@media screen and (max-width: 882px) {
    .iconReviews1, .iconReviews2, .iconReviews3, .iconReviews4,
    .iconReviews5, .iconReviews6{
        height: 40px;
    }
}


@media screen and (max-width: 882px) {
    .homeSectNine {
        padding: 25px 5%;
    }
}
@media screen and (max-width: 882px) {
    .contInputs {
        flex-direction: column;
    }
}
@media screen and (max-width: 882px) {
    .inputfstSectForm {
        width: 100%;
    }
}
@media screen and (max-width: 882px) {
    .inputfstSectForm2 {
        width: 80%;
    }
}


@media screen and (max-width: 882px) {
    .contInputMail {
        justify-content: center;
    }
}
/* 666px */
/* @media screen and (max-width: 694px) {
    .homeSectFour {
        height: 120px;
    }
} */
@media screen and (max-width: 694px) {
    .txtFourSectTitle {
        font-size: 26px;
    }
}
@media screen and (max-width:682px) {
    .txtFourSectSubTitle {
        font-size: 18px;
    }
}
/* 500px */
@media screen and (max-width: 550px) {
    .txtSThreeSubM, .txtSTDesct {
        font-size: 16px;
    }
}

@media screen and (max-width: 550px) {
    .txtSThree {
        font-size: 40px;
        line-height: 35px;
    }
}
@media screen and (max-width:550px) {
    .homeSectThree {
        height: 210px;
    }
}

/* @media screen and (max-width: 550px) {
    .homeSectFour {
        height: 70px;
    }
} */
@media screen and (max-width: 550px) {
    .txtFourSectTitle {
        font-size: 20px;
    }
}
@media screen and (max-width: 550px) {
    .txtFourSectSubTitle {
        font-size: 14px;
    }
}

@media screen and (max-width: 500px) {
    .contlogo1SectSix, .contlogo2SectSix, .contlogo3SectSix, .contlogo4SectSix, .contlogo2-2SectSix {
        height: 40px;
    }
}

@media screen and (max-width: 500px) {
    .txtTitleSectSeven {
        font-size: 40px;
    }
}
@media screen and (max-width: 500px) {
    .txtTitleSectEight {
        font-size: 18px;
    }
}


@media screen and (max-width: 409px) {
    .txtFourSectTitle {
        font-size: 16px;
    }
}


@media screen and (max-width: 409px) {
    .FSMob {
        font-size: 48px;
    }
}
/* Keyframes */

@keyframes slidetitle {
    from {
      margin-right:50%;
    }
  
    to {
      margin-right: 0%;
    }
}
@keyframes slidetitle2 {
    from {
      margin-right: 70%;
    }
  
    to {
      margin-right: 0%;
    }
}

@media screen and (max-width: 600px) {
    .txtTitleSectNine {
        font-size: 20px;
    }
}