.contFooterDesk {
    width: 100%;
    background-color: #403c3c;
    padding: 2.5% 1.5%;
}
.fstSectFoot{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.txtBF{
    color: #fff;
    font-weight: bold;
    margin: 0;
    text-align: center;
    margin-bottom: 0.5rem;
}
.BtnFS{
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.contBtnFstSectF{
    text-decoration: none;
    width: 40%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    border: 3px solid rgb(243, 197, 31);
    border-radius: 50px;
    padding: .5% 1%;
}
.btnStSect{
    text-decoration: none;
    font-size: 14px;
    padding: 0px 6px;
    margin: 0;
    color: #fff;
    background-color: transparent;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.imgCircleArrow{
    width: 25px;
    height: 25px;
    background-image: url('../../assets/btnCircleArrow.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

/* secondSectionFooter */

.secSectFoot{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2.5% 0px;
}
.contSocialMedia{
    width: 20%;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.socialMediaIcon{
    width: 30px;
    height: 30px;
    background-image: url('../../assets/fbIcon.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.socialMediaIcon1{
    width: 30px;
    height: 30px;
    background-image: url('../../assets/twitIcon.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.socialMediaIcon2{
    width: 30px;
    height: 30px;
    background-image: url('../../assets/instaIcon.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.socialMediaIcon3{
    width: 30px;
    height: 30px;
    background-image: url('../../assets/ytIcon.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.linkSocialMedia{
    cursor: pointer;
}

/* thirdSectionFooter */

.thiSectFoot{
    width: 100%;
    padding-bottom: 2.5%;
}
.txtTitleContacts{
    margin: 0px;
    width: 100%;
    text-align: center;
    font-size: 21px;
    color: rgb(243, 197, 31);
    padding-bottom: 2%;
}

.contInfo{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.contLeftF{
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.contRightF{
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contLeftFInt{
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.contImgCircleMess{
    width: 65px;
    height: 65px;
    background-image: url('../../assets/circleMessage.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.contImgCircleMess2{
    width: 65px;
    height: 65px;
    background-image: url('../../assets/circlePhone.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.txtSubTContact{
    margin: 0px;
    color: #fff;
    font-size: 21px;
}
.contRightFInt{
    width: 75%;
    height: 133.6px;
    border-left: 3px solid #fff;
    padding-left: 5%;
}

.contRightFInt2{
    width: 75%;
    height: 133.6px;
    border-left: 3px solid #fff;
    padding-left: 5%;
    display: flex;
    align-items: center;
    justify-content: left;
}

.fourthSect{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.txtFinalFooter{
    width: 80%;
    color: rgb(243, 197, 31);
    font-size: 14px;
    text-align: center;
    margin: 0;
}


/* Querys Footer */
@media screen and (max-width: 882px) {
    .contFooterDesk {
        padding: 5% 1.5%;
    }
}
@media screen and (max-width: 882px) {
    .secSectFoot, .thiSectFoot, .txtTitleContacts {
        padding-bottom: 20px;
    }
}
@media screen and (max-width: 882px) {
    .fstSectFoot {
        flex-direction: column;
    }
}
@media screen and (max-width: 882px) {
    .BtnFS {
        width: 80%;
        padding-bottom: 18px;
    }
}
@media screen and (max-width: 882px) {
    .contSocialMedia {
        width: 40%;
    }
}
@media screen and (max-width: 882px) {
    .contInfo {
        flex-direction: column;
    }
}
@media screen and (max-width: 882px) {
    .contLeftF, .contRightF {
        width: 80%;
    }
}
@media screen and (max-width: 882px) {
    .txtSubTContact {
        font-size: 16px;
    }
}

@media screen and (max-width: 750px) {
    .btnStSect{
        font-size: 12px;
    }
}

@media screen and (max-width: 550px) {
    .txtSubTContact {
        font-size: 12px;
    }
}
@media screen and (max-width: 550px) {
    .contRightFInt {
        display: flex;
        flex-direction: column;
        align-items: left;
        justify-content: center;
    }
}
@media screen and (max-width: 550px) {
    .contRightFInt2, .contRightFInt {
        height: 100px;
    }
}
@media screen and (max-width: 550px) {
    .contImgCircleMess, .contImgCircleMess2 {
        width: 50px;
        height: 50px;
    }
}
@media screen and (max-width: 550px) {
    .contSocialMedia{
        width: 60%;
    }
}
