.playersSectOne {
    width: 100%;
    /* padding: 50px 5%; */
    background-color: #f0ac0c;
    display: flex;
    align-items: center;
    justify-content: center;
}
.playersSectOne2 {
    width: 100%;
    /* padding: 50px 5%; */
    background-color: #403c3c;
    display: flex;
    align-items: center;
    justify-content: center;
}
.paddingDesk {
    margin-top: 20px;
}
.CPS1L {
    width: 45%;
    padding: 50px 5% 50px 5%;
    background-color: #f0ac0c;
    display: flex;
    align-items: center;
    justify-content: left;
}
.txtTitleCPS1{
    margin: 0;
    color: #fff;
    font-size: 22px;
    width: 100%;
    text-align: left;
}
.txtTitleCPS1-2{
    font-size: 28px;
}
.CPS1R {
    width: 55%;
    height: 100%;
    padding: 50px 5% 50px 5%;
    background-color: #403c3c;
}
.txtSubTitleCPS1{
    margin: 0;
    color: #fff;
}
.txtSubTitleCPS1Y{
    color: rgb(243, 197, 31);
}

/* Section players */
.contSectPlayers{
    width: 100%;
    padding: 50px 5%;
    background-image: url('../../assets/BGPlayers02.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.sectPlayers{
    width: 75%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.contDuplexCard {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.contCard{
    position: relative;
    width: 196px;
    height: 309.4px;
    background-image: url('../../assets/BGPlayerCard.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.flat{
    width: 40px;
    height: 40px;
    top: 10px;
    position: absolute;
    z-index: 990;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.urlFlat1{
    background-image: url('../../assets/flats/flatBrasil.png');
}
.urlFlat2{
    background-image: url('../../assets/flats/flatSpain.png');
}
.urlFlat3{
    background-image: url('../../assets/flats/flatRWB.png');
}
/* .urlFlat4{
    background-image: url('../../assets/flats/flatBrasil.png');
} */
/* .urlFlat5{
    background-image: url('../../assets/flats/flatBrasil.png');
} */
/* .urlFlat6{
    background-image: url('../../assets/flats/flatBrasil.png');
} */
/* .urlFlat7{
    background-image: url('../../assets/flats/flatBrasil.png');
} */
/* .urlFlat8{
    background-image: url('../../assets/flats/flatBrasil.png');
} */

.urlFlat9{
    background-image: url('../../assets/flats/flatBRY.png');
}
.urlFlat10{
    background-image: url('../../assets/flats/flatGWR.png');
}
.urlFlat11{
    background-image: url('../../assets/flats/flatArgentina.png');
}
/* .urlFlat12{
    background-image: url('../../assets/flats/flatRWB.png');
} */
/* .urlFlat13{
    background-image: url('../../assets/flats/flatRWB.png');
}
.urlFlat14{
    background-image: url('../../assets/flats/flatRWB.png');
}
.urlFlat15{
    background-image: url('../../assets/flats/flatRWB.png');
}
.urlFlat16{
    background-image: url('../../assets/flats/flatRWB.png');
} */
/* .urlFlat17{
    background-image: url('../../assets/flats/flatRWB.png');
}
.urlFlat18{
    background-image: url('../../assets/flats/flatRWB.png');
} */
/* .urlFlat19{
    background-image: url('../../assets/flats/flatRWB.png');
}
.urlFlat20{
    background-image: url('../../assets/flats/flatRWB.png');
}
.urlFlat21{
    background-image: url('../../assets/flats/flatRWB.png');
}
.urlFlat22{
    background-image: url('../../assets/flats/flatRWB.png');
}
.urlFlat23{
    background-image: url('../../assets/flats/flatRWB.png');
}
.urlFlat24{
    background-image: url('../../assets/flats/flatRWB.png');
}
.urlFlat25{
    background-image: url('../../assets/flats/flatRWB.png');
} */
.urlFlat26{
    background-image: url('../../assets/flats/flatYBR.png');
}
.urlFlat27{
    background-image: url('../../assets/flats/flatRWB.png');
}
.urlFlat28{
    background-image: url('../../assets/flats/flatRWBRW.png');
}
.urlFlat29{
    background-image: url('../../assets/flats/flatEcuador.png');
}
/* .urlFlat30{
    background-image: url('../../assets/flats/');
} */
.urlFlat40{
    background-image: url('../../assets/flats/flatBWRY.png');
}
.urlFlat41{
    background-image: url('../../assets/flats/flatGWRS.png');
}
.urlFlat42{
    background-image: url('../../assets/flats/flatRG.png');
}
.urlFlat43{
    background-image: url('../../assets/flats/flatRWCB.png');
}
.urlFlat44{
    background-image: url('../../assets/flats/flatWBR.png');
}
.urlFlat45{
    background-image: url('../../assets/flats/flatSunWB.png');
}
/* .urlFlat46{
    background-image: url('../../assets/flats/flatRWCB.png');
} */
/* .urlFlat47{
    background-image: url('../../assets/flats/flatRWCB.png');
} */
.urlFlat48{
    background-image: url('../../assets/flats/flatYBSR.png');
}


.playerImg{
    width: 139.5px;
    height: 152px;
    top: 34.5PX;
    left: 27px;
    border-radius: 4px;
    position: absolute;
    z-index: 989;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.urlPlayerImg1{
    background-image: url('../../assets/palyers/GersonSantosImg.png');
}
.urlPlayerImg2{
    background-image: url('../../assets/palyers/playerRaulMoroPrescoli.png');
}
.urlPlayerImg3{
    background-image: url('../../assets/palyers/playerBobbyAdekanye.png');
}
.urlPlayerImg4{
    background-image: url('../../assets/palyers/playerCarlosAlgarraLopez.png');
}
.urlPlayerImg5{
    background-image: url('../../assets/palyers/playerCarlosBenitez.png');
}
.urlPlayerImg6{
    background-image: url('../../assets/palyers/playerJavierRodriguezLancho.png');
}
.urlPlayerImg7{
    background-image: url('../../assets/palyers/playerAlejandroGinard.png');
}
.urlPlayerImg8{
    background-image: url('../../assets/palyers/playerMarcosSanchez.png');
}
.urlPlayerImg9{
    background-image: url('../../assets/palyers/playerArmelBellaKotchap.png');
}
.urlPlayerImg10{
    background-image: url('../../assets/palyers/playerRamyBensebaini.png');
}
.urlPlayerImg11{
    background-image: url('../../assets/palyers/playerThiagoAlmada.png');
}
.urlPlayerImg12{
    background-image: url('../../assets/palyers/playerAgustinAlmendra.png');
}
.urlPlayerImg13{
    background-image: url('../../assets/palyers/playerExequielZeballos.png');
}
.urlPlayerImg14{
    background-image: url('../../assets/palyers/playerMatiasPalacios.png');
}
.urlPlayerImg15{
    background-image: url('../../assets/palyers/playerGinoInfantino.png');
}
.urlPlayerImg16{
    background-image: url('../../assets/palyers/playerFrancescoLoCelso.png');
}
.urlPlayerImg17{
    background-image: url('../../assets/palyers/playerLucianoVera.png');
}
.urlPlayerImg18{
    background-image: url('../../assets/palyers/playerGabrielVeron.png');
}
.urlPlayerImg19{
    background-image: url('../../assets/palyers/playerJeanPyerreCasagrande.png');
}
.urlPlayerImg20{
    background-image: url('../../assets/palyers/playerDalbert.png');
}
.urlPlayerImg21{
    background-image: url('../../assets/palyers/playerKaikeRocha.png');
}
.urlPlayerImg22{
    background-image: url('../../assets/palyers/playerCharlesMatos.png');
}
.urlPlayerImg23{
    background-image: url('../../assets/palyers/playerPedroLucas.png');
}
.urlPlayerImg24{
    background-image: url('../../assets/palyers/playerVitinho.png');
}
.urlPlayerImg25{
    background-image: url('../../assets/palyers/playerYagoDePaula.png');
}
.urlPlayerImg26{
    background-image: url('../../assets/palyers/playerYairoMoreno.png');
}
.urlPlayerImg27{
    background-image: url('../../assets/palyers/playerGustavoCarbajal.png');
}
.urlPlayerImg28{
    background-image: url('../../assets/palyers/playerDomagojBradaric.png');
}
.urlPlayerImg29{
    background-image: url('../../assets/palyers/playerJoseCifuentes.png');
}
.urlPlayerImg30{
    background-image: url('../../assets/palyers/playerFelixTorres.png');
}
.urlPlayerImg31{
    background-image: url('../../assets/palyers/playerAronRodriguez.png');
}
.urlPlayerImg32{
    background-image: url('../../assets/palyers/playerGori.png');
}
.urlPlayerImg33{
    background-image: url('../../assets/palyers/playerJofreCarreras.png');
}
.urlPlayerImg34{
    background-image: url('../../assets/palyers/playerBijanRadulovic.png');
}
.urlPlayerImg35{
    background-image: url('../../assets/palyers/playerChrisianGomez.png');
}
.urlPlayerImg36{
    background-image: url('../../assets/palyers/playerDavidNavarro.png');
}
.urlPlayerImg37{
    background-image: url('../../assets/palyers/playerJoseSolves.png');
}
.urlPlayerImg38{
    background-image: url('../../assets/palyers/playerRogerGarciaCayon.png');
}
.urlPlayerImg39{
    background-image: url('../../assets/palyers/playerManelMartinez.png');
}
.urlPlayerImg40{
    background-image: url('../../assets/palyers/playerKoloMuani.png');
}
.urlPlayerImg41{
    background-image: url('../../assets/palyers/playerNicoZiniolo.png');
}
.urlPlayerImg42{
    background-image: url('../../assets/palyers/playerLazarCarevic.png');
}
.urlPlayerImg43{
    background-image: url('../../assets/palyers/playerIvanFranco.png');
}
.urlPlayerImg44{
    background-image: url('../../assets/palyers/playerVladimirMoskvichev.png');
}
.urlPlayerImg45{
    background-image: url('../../assets/palyers/playerBrianRodriguez.png');
}
.urlPlayerImg46{
    background-image: url('../../assets/palyers/playerLeandroSuhr.png');
}
.urlPlayerImg47{
    background-image: url('../../assets/palyers/playerLautaroDeLeon.png');
}
.urlPlayerImg48{
    background-image: url('../../assets/palyers/playerYangelHerrera.png');
}

.clubImage{
    width: 30px;
    height: 30px;
    top: 208PX;
    left: 142px;
    position: absolute;
    z-index: 989;
    background-color: #fff;
    border-radius: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.urlImageClub1 {
    background-image: url('../../assets/clubs/clubM.png');
}
.urlImageClub2 {
    background-image: url('../../assets/clubs/clubGWBE.png');
}
.urlImageClub3 {
    background-image: url('../../assets/clubs/clubFCCROTONE.png');
}
.urlImageClub4 {
    background-image: url('../../assets/clubs/clubYB.png');
}
.urlImageClub5 {
    background-image: url('../../assets/clubs/clubBYRB.png');
    background-size: 24px 25px;
}
.urlImageClub6 {
    background-image: url('../../assets/clubs/clubYRBW.png');
    background-size: 18px 26px;
}
.urlImageClub7 {
    background-image: url('../../assets/clubs/clubBWR.png');
    background-size: 18px 25px;
}
.urlImageClub8 {
    background-image: url('../../assets/clubs/clubCVF.png');
    background-size: 20px 25px;
}
.urlImageClub9 {
    background-image: url('../../assets/clubs/clubVFL.png');
}
.urlImageClub10 {
    background-image: url('../../assets/clubs/clubB.png');
}
.urlImageClub11 {
    background-image: url('../../assets/clubs/clubAtlanta.png');
}
.urlImageClub12 {
    background-image: url('../../assets/clubs/clubCABJ.png');
}
/* .urlImageClub13 {
    background-image: url('../../assets/clubs/clubCVF.png');
} */
/* .urlImageClub14 {
    background-image: url('../../assets/clubs/clubCVF.png');
    background-size: 20px 25px;
} */
.urlImageClub15 {
    background-image: url('../../assets/clubs/clubCARC.png');
}
/* .urlImageClub16 {
    background-image: url('../../assets/clubs/clubCVF.png');
    background-size: 20px 25px;
} */
/* .urlImageClub17 {
    background-image: url('../../assets/clubs/clubCVF.png');
    background-size: 20px 25px;
} */
.urlImageClub18 {
    background-image: url('../../assets/clubs/clubPalmeiras.png');
}
.urlImageClub19 {
    background-image: url('../../assets/clubs/clubGremio.png');
}
/* .urlImageClub20 {
    background-image: url('../../assets/clubs/clubCVF.png');
    background-size: 20px 25px;
} */
.urlImageClub21 {
    background-image: url('../../assets/clubs/clubWR.png');
}
.urlImageClub22 {
    background-image: url('../../assets/clubs/clubBRW1999.png');
}
/* .urlImageClub23 {
    background-image: url('../../assets/clubs/clubCVF.png');
    background-size: 20px 25px;
} */
.urlImageClub24 {
    background-image: url('../../assets/clubs/clubGFC.png');
}
.urlImageClub25 {
    background-image: url('../../assets/clubs/clubSGWR.png');
}
.urlImageClub26 {
    background-image: url('../../assets/clubs/clubPachuca.png');
}
/* .urlImageClub27 {
    background-image: url('../../assets/clubs/clubCVF.png');
    background-size: 20px 25px;
} */
.urlImageClub28 {
    background-image: url('../../assets/clubs/cubLosc.png');
}
.urlImageClub29 {
    background-image: url('../../assets/clubs/clubLA.png');
}
.urlImageClub30 {
    background-image: url('../../assets/clubs/clubBGWG.png');
}
.urlImageClub31 {
    background-image: url('../../assets/clubs/clubUB.png');
}
.urlImageClub32{
    background-image: url('../../assets/clubs/clubYRBW.png');
    background-size: 18px 26px;
}
/* .urlImageClub33 {
    background-image: url('../../assets/clubs/clubCVF.png');
    background-size: 20px 25px;
} */
.urlImageClub34 {
    background-image: url('../../assets/clubs/clubHJK.png');
}
.urlImageClub35 {
    background-image: url('../../assets/clubs/clubCVF.png');
    background-size: 20px 25px;
}
.urlImageClub36 {
    background-image: url('../../assets/clubs/clubCVF.png');
    background-size: 20px 25px;
}
.urlImageClub37 {
    background-image: url('../../assets/clubs/clubHCF.png');
}
.urlImageClub38 {
    background-image: url('../../assets/clubs/clubCVF.png');
    background-size: 20px 25px;
}
.urlImageClub39 {
    background-image: url('../../assets/clubs/clubSI.png');
}
.urlImageClub40 {
    background-image: url('../../assets/clubs/clubGY.png');
}
.urlImageClub41 {
    background-image: url('../../assets/clubs/clubRoma.png');
}
.urlImageClub42 {
    background-image: url('../../assets/clubs/clubGWRB.png');
}
.urlImageClub43 {
    background-image: url('../../assets/clubs/clubLibertad.png');
}
.urlImageClub44 {
    background-image: url('../../assets/clubs/clubGWR1991.png');
}
.urlImageClub45 {
    background-image: url('../../assets/clubs/clubGWR1991.png');
}
.urlImageClub46 {
    background-image: url('../../assets/clubs/clubGWR1991.png');
}
.urlImageClub47 {
    background-image: url('../../assets/clubs/clubGWR1991.png');
}
.urlImageClub48 {
    background-image: url('../../assets/clubs/clubGWR1991.png');
}
/* .urlImageClub45 {
    background-image: url('../../assets/clubs/clubSI.png');
    background-size: 20px 25px;
} */
.urlImageClub46 {
    background-image: url('../../assets/clubs/clubPlazaColonia.png');
}
.urlImageClub47 {
    background-image: url('../../assets/clubs/clubSI.png');
    background-size: 20px 25px;
}
.urlImageClub48 {
    background-image: url('../../assets/clubs/clubSI.png');
    background-size: 20px 25px;
}



.position{
    width: 87%;
    padding-left: 13%;
    position: absolute;
    z-index: 989;
    top: 200PX;

}
.txtPosition{
    font-size: 7px;
    margin: 0;
    color: rgb(243, 197, 31);
}
.positionWhite{
    color: #fff;
}
.playerName{
    width: 100%;
    text-align: center;
    position: absolute;
    z-index: 989;
    top: 242PX;
}
.txtPlayerName{
    font-size: 11px;
    margin: 0;
    color: rgb(243, 197, 31);
}

/* Section Two */

.playersSectTwo{
    width: 100%;
    padding: 50px 5%;
    background-image: url('../../assets/BGYellowSil.png');
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}
.txtSpaceSectT{
    width: 25%;
}
.ThrdZone{
    width: 25%;
    height: 215.2px;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.subSquareBlack{
    background-color: #000;
    padding: 10px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.txt3Trys{
    margin: 0;
    font-size: 10PX;
    color: rgb(243, 197, 31);
}
.txtSect2{
    width: 50%;
    margin: 0;
}
.txt1ST{
    color: #fff;
    font-size: 18px;
}
.txt2ST {
    font-size: 32px;
}
.txt2BST {
    color: #000;
}

/* Media Querys */
@media screen and (min-width: 1364px) {
    .playersSectOne, .playersSectOne2 {
        background-color: #403c3c;
    }
}

@media screen and (max-width: 1200px) {
    .sectPlayers {
        width: 100%;
    }
}

@media screen and (max-width: 1200px) {
    .sectPlayers {
        width: 100%;
    }
}




@media screen and (max-width: 882px) {
    .playersSectOne, .playersSectOne2 {
        flex-direction: column;
    }
}
@media screen and (max-width: 882px) {
    .CPS1R, .CPS1L {
        width: 100%;
        padding: 25px 5% 25px 5%;
    }
}
@media screen and (max-width: 882px) {
    .sectPlayers {
        width: 70%;
        flex-direction: column;
    }
}
@media screen and (max-width: 650px) {
    .sectPlayers {
        width: 80%;
        flex-direction: column;
    }
}
@media screen and (max-width: 882px) {
    .contDuplexCard {
        width: 100%;
    }
}

@media screen and (max-width: 882px) {
    .marginDesk {
        margin-top: 0px;
    }
}

@media screen and (max-width: 882px) {
    .txtSpaceSectT {
        display: none;
    }
}

@media screen and (max-width: 882px) {
    .contSectPlayers {
        padding: 25px 5%;
    }
}
@media screen and (max-width: 882px) {
    .playersSectTwo {
        padding: 25px 5%;
        flex-direction: column;
    }
}
@media screen and (max-width: 882px) {
    .ThrdZone {
       height: auto;
       padding-top: 20px;
       justify-content: left;
       width: 80%;
       padding-left: 20%;
    }
}
@media screen and (max-width: 882px) {
    .txtSect2 {
       width: 80%;
       padding-left: 20%;
    }
}
@media screen and (max-width: 882px) {
    .playersSectTwo {
       background-position: 25% 75%;
    }
}
@media screen and (max-width: 710px) {
    .playersSectTwo {
       background-position: 18% 82%;
    }
}

/* 550px  */
@media screen and (max-width: 550px) {
    .sectPlayers {
        width: 100%;
    }
}
@media screen and (max-width: 550px) {
    .contDuplexCard {
        flex-direction: column;
    }
}


/* 500 */
@media screen and (max-width: 500px) {
    .playersSectTwo {
       background-position: 25% 85%;
    }
}

@media screen and (max-width: 500px) {
    .ThrdZone {
       padding-left: 0%;
    }
}
@media screen and (max-width: 500px) {
    .txtSect2 {
       padding-left: 0%;
    }
}
@media screen and (max-width: 500px) {
    .txt2ST {
       font-size: 26px;
    }
}
@media screen and (max-width: 500px) {
    .txtSect2, .ThrdZone{
       width: 100%;
    }
}
