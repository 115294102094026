.contTitleLogo{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.contLogoLogin{
    width: 75px;
    height: 75px;
    background-image: url('../assets/logoToken.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
.contTitleFormLogin{
    width: 100%;
    text-align: center;
    font-size: 35px;
    margin: 0;
    color: #fff;
}
.contSubTitleFormLogin{
    width: 100%;
    text-align: center;
    font-size: 20px;
    margin: 0;
    color: #fff;
}
.formLogin{
    width: 100%;
    padding: 4% 15%;
    border-radius: 10px;
    background-color: rgba(255,255,255,0.15);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.contInputMailLogin{
    width: 100%;
}
.inputfstSectFormLogin{
    padding: 2px 10px;
    outline: none;
    color: #fff;
    width: 100%;
    background-color: transparent;
    border: none;
    border-bottom:2px solid #f0ac0c;
    border-radius: 6px;
}
.inputfstSectFormLogin::placeholder { color: #fff; }

.buttonRegGoogle{
    color: #fff;
    font-size: 14px;
    text-align: center;
    width: 100%;
    padding: 1% 0%;
    background-color: #dc4854;
    text-decoration: none;
    border: none;
    margin-bottom: 10px;
    border-radius: 4px;
}
.buttonRegFB{
    color: #fff;
    font-size: 14px;
    text-align: center;
    width: 100%;
    padding: 1% 0%;
    background-color: #1884fc;
    text-decoration: none;
    border: none;
    border-radius: 4px;
}
.contButtonSendFormLogin{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.btnSendFormLogin{
    width: auto;
    background-color: #f0ac0c;
    font-weight: bold;
    color: #fff;
    text-align: center;
    padding: 10px 30px;
    border: none;
    border-radius: 12px;
    text-decoration: none;
    font-size: 14px;
}


@media screen and (max-width: 600px) {
    .formLogin {
        padding: 4% 5%;
    }
}
@media screen and (max-width: 600px) {
    .contTitleFormLogin {
        font-size: 25px;
    }
}
@media screen and (max-width: 600px) {
    .contSubTitleFormLogin {
        font-size: 16px;
    }
}
@media screen and (max-width: 600px) {
    .inputfstSectFormLogin, .inputfstSectForm {
        font-size: 12px;
    }
}
@media screen and (max-width: 600px) {
    .contLogoLogin {
        width: 60px;
        height: 60px;
    }
}