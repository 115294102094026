.btnBorderTop {
    color: white;
    font-size: 13px;
    width: 100%;
    padding: 8px 5px;
    border: none;
    border-top: 3px solid rgb(243, 197, 31);
    background-color: transparent;
    cursor: pointer;
}
.btnBorderNone {
    color: white;
    font-size: 12.5px;
    width: 100%;
    padding: 8px 5px;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.btnBorderTopMob {
    color: #fff;
    font-weight: bold;
    width: 100%;
    padding: 8px 5px;
    border: none;
    border: 3px solid #fff;
    border-radius: 20px;
    background-color: #28292d;
    cursor: pointer;
    font-size: 12px;
}
.btnBorderNoneMob {
    color: #000;
    font-size: 12px;
    font-weight: bold;
    width: 100%;
    padding: 8px 5px;
    border: none;
    border: 3px solid #000;
    border-radius: 20px;
    background-color: transparent;
    cursor: pointer;
}

.btnLogIn {
    text-align: right;
    color: white;
    font-weight: bold;
    width: 100%;
    padding: 8px 5px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 13px;
}
.btnLogIn2 {
    width: 60%;
}
.btnLogInMob {
    text-align: center;
    font-size: 12px;
    color: #000;
    font-weight: bold;
    width: 100%;
    padding: 8px 5px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding-bottom: 10px;
}

.btnCreateAccountLink {
    width: 20%;
    padding: 4px 5px;
}
.btnCreateAccountLink1 {
    width: 100%;
}

.btnCreateAccount {
    font-size: 10px;
    border-radius: 25px;
    font-weight: bold;
    width: 100%;
    border: 3px solid white;
    background-color:rgb(243, 197, 31);
    cursor: pointer;
    padding: 5PX 0PX;
}

.btnCreateAccountMob {
    color: #000;
    font-size: 11px;
    border-radius: 25px;
    font-weight: bold;
    width: 100%;
    padding: 4px 5px;
    border: 3px solid #000;
    background-color:transparent;
    cursor: pointer;
}

.btnWsitchLanguage {
    color: white;
    font-size: 11px;
    width: 33.33%;
    padding: 1px 5px;
    border: none;
    border-right: 3px solid rgb(243, 197, 31);
    background-color: transparent;
    cursor: pointer;
}

.btnWsitchLanguage2 {
    color: white;
    font-size: 11px;
    width: 33.33%;
    padding: 1px 5px;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

/* FOOTER BUTTONS */

/* Querys */

@media screen and (max-width: 1067px) {
    .btnBorderTop {
        font-size: 11px;
    }
}
@media screen and (max-width: 1067px) {
    .btnBorderNone {
        font-size: 11px;
    }
}

@media screen and (max-width: 904px) {
    .btnLogIn {
        font-size: 13px;
    }
}

@media screen and (max-width: 934px) {
    .btnCreateAccount {
        font-size: 9px;
    }
}

@media screen and (max-width: 894px) {
    .btnBorderTop {
        font-size: 10px;
    }
}
@media screen and (max-width: 894px) {
    .btnBorderNone {
        font-size: 10px;
    }
}


@media screen and (max-width: 550px) {
    .btnCreateAccountMob {
        font-size: 9px;
    }
}